import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getRouteLineFromRouteFare (id) {
  return apiRequests.get(`/v1/route-fare/` + id + '/route-line/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updateRouteFareByDateUpdated ({ id, fare, dateUpdated }) {
  return apiRequests.patch(`/v1/route-fare/${id}/by-date-updated/`, { fare, dateUpdated }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createRouteFareAndAssociateToRoute ({
  stRouteRouteFareId,
  stRouteId,
  routeLabel,
  fare,
  agencyId,
  routeIdList,
  routeLine,
  potentialFare,
  potentialFareId
}) {
  return apiRequests.post(`/v1/route-fare/by-st-route/`, {
    stRouteRouteFareId,
    stRouteId,
    routeLabel,
    fare,
    agencyId,
    routeIdList,
    routeLine,
    potentialFare,
    potentialFareId
  }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getRouteLineFromRouteFare,
  updateRouteFareByDateUpdated,
  createRouteFareAndAssociateToRoute
}
