<template>
  <div :class="name">
    <div v-if="$router.currentRoute.name === 'routes-route-fare-permutations'" v-resize="onResize">
      <h1 class="text-xs-center" id="routeHeader" ref="routeHeader">Route Fare Permutations</h1>
      <div v-if="!currentPermutationsError && currentRoute">
        <div id="routeControlBar" ref="routeControlBar" class="mx-3">
          <div class="layout justify-space-between wrap">
            <div class="mb-2 grow" style="overflow-y: auto">
              <div><strong>Route:</strong> {{currentRoute.label}}</div>
              <div v-if="hasRouteIdListChanged"><small><code>{{hasRouteIdListChanged}}</code></small></div>
            </div>
            <div class="mb-2 ">
              <v-btn class="mr-2" @click="startPermutationsJob" v-if="isPermutationsPending">
                <v-icon left>fas fa-tasks-alt</v-icon> Start/refresh Permutations Job
              </v-btn>
              <v-tooltip v-if="isPermutationsRunning" top>
                <template v-slot:activator="{ on }">
                  <v-progress-circular color="primary" size="30" width="3" indeterminate v-on="on" />
                </template>
                <span v-html="permutationsJobStatus" />
              </v-tooltip>
              <v-tooltip v-if="permutationsJobError" top>
                <template v-slot:activator="{ on }">
                  <v-btn small class="ml-2" fab @click="initPermutationsJobMonitor">
                    <v-icon class="error--text" v-on="on">fas fa-exclamation-triangle</v-icon>
                  </v-btn>
                </template>
                <span>Permutation jobs monitor error: {{ permutationsJobError }}</span>
              </v-tooltip>
              <v-tooltip v-if="permutationsSuccess" top>
                <template v-slot:activator="{ on }">
                  <v-btn flat icon><v-icon class="success--text" v-on="on">fas fa-check-circle</v-icon></v-btn>
                </template>
                <span>Permutations job complete</span>
              </v-tooltip>
              <v-tooltip v-if="isPermutationsCancelled" top>
                <template v-slot:activator="{ on }">
                  <v-btn flat icon><v-icon class="error--text" v-on="on">fas fa-ban</v-icon></v-btn>
                </template>
                <span>Current permutations job has stopped</span>
              </v-tooltip>
              <v-btn class="ml-2" @click="stopPermutationsJob" v-if="isPermutationsRunning">
                <v-icon left>fas fa-ban</v-icon> Stop Permutations Job
              </v-btn>
            </div>
          </div>
          <div class="mb-2 layout justify-space-between">
            <div>
              <v-text-field
                solo
                clearable
                hide-details
                v-model="searchKeywords"
                append-icon="fal fa-search"
                :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
                :disabled="apiInProgress"
                type="search"
                placeholder="Filter by route label"
              />
            </div>
            <div>
              <div class="layout justify-start">
                <div>
                  <download-button
                    label="Export as CSV"
                    download-type="text/csv"
                    label-icon="fas fa-file-spreadsheet"
                    :on-click="onDownloadButtonClicked"
                    :on-download="onDownloadingRoutes"
                    :file-name="csvDataFilename || ''"
                    :disabled="!routeFares.length"
                  />
                </div>
                <div>
                  <v-btn :disabled="apiInProgress" @click="getRouteFares">Refresh List <v-icon small right>fas fa-sync-alt</v-icon></v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div ref="bulkEditorHeader" class="table-row layout justify-space-around pa-2 bulk-editor-header">
            <div>From</div>
            <div>To</div>
            <div>Date Updated</div>
            <div class="table-cell--input"><span v-show="countryCurrency">({{countryCurrency && countryCurrency.currencySymbol}}) </span>Fare</div>
            <div>&nbsp;</div>
          </div>
          <div :style="`height: ${farePermutationsContainerHeight}px; overflow-y: auto`">
            <v-progress-circular style="position: absolute; top: 45%; left: 45%" color="primary" size="80" width="8" indeterminate v-if="apiInProgress" />
            <RecycleScroller
              v-if="enableVirtualList"
              class="scroller"
              :items="routeFares"
              :item-size="60"
              key-field="id"
              v-slot="{ item, index }"
              :page-mode="true"
              :emit-update="true"
              @update="visibleBulkEditIndexes"
            >
              <div class="table-row table-row--divider layout justify-space-around align-center px-2">
                <div>
                  <v-tooltip v-if="item.routeFareObj.routeLabel && item.routeFareObj.permutationLabelFrom !== item.routeFareObj.routeLabelFrom" top>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">fas fa-info-circle</v-icon>
                    </template>
                    <span v-html="`Original label: ${item.routeFareObj.routeLabel}`" />
                  </v-tooltip>
                  {{item.routeFareObj.permutationLabelFrom || item.routeFareObj.routeLabelFrom}}
                </div>
                <div>
                  {{item.routeFareObj.permutationLabelTo || item.routeFareObj.routeLabelTo}}
                </div>
                <div>{{item.routeFareObj.dateUpdatedLabel}}</div>
                <div class="table-cell--input">
                  <currency-field
                    v-model="item.routeFareObj.fareDollar"
                    @blur="routeFarePossiblyEdited(item)"
                    :child-props="currencyFieldChildProps(item, index)"
                  />
                </div>
                <div>
                  <v-btn
                    small
                    :loading="item.apiState === 'loading'"
                    :disabled="!isRouteFareEditOccurred(item)"
                  >
                    Save
                    <v-icon small class="success--text" v-if="item.apiState === 'success'" right>fas fa-check-circle</v-icon>
                    <v-icon small class="error--text" v-if="item.apiState === 'error'" right>fas fa-exclamation-triangle</v-icon>
                  </v-btn>
                </div>
              </div>
            </RecycleScroller>
          </div>
          <div ref="bulkEditorFooter" id="bulkEditorFooter" class="pa-2 layout justify-end bulk-editor-footer">
            <div><small>Total: {{routeFares.length}}</small></div>
          </div>
        </div>
      </div>
      <v-alert :value="currentPermutationsError" type="error">
        <p>{{currentPermutationsError}}</p>
        <p><a href @click.stop="refreshView()">Retry</a> or <router-link :to="{ name: 'routes', params: { routeId } }">Return to route</router-link></p>
      </v-alert>
    </div>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
